import * as Coupons from 'EventLevel/components/coupons';
import { ErrorBoundary } from 'bv-error-boundary';

/* eslint-disable react/destructuring-assignment */
const CouponFactory = (coupon) => {
  const Coupon = Coupons[`${coupon.coupon.replace(/Coupon$/, '')}Coupon`];

  if (!Coupon) return null;

  return (
    <ErrorBoundary fallbackComponent={() => null}>
      <Coupon key={coupon.id} coupon={coupon} />
    </ErrorBoundary>
  );
};

export default CouponFactory;
